import { createContext, FunctionComponent, useEffect, useState } from 'react'
import { loadClientServices } from '../services'

export const ServicesContext = createContext<ISercicesContext>(
  {} as ISercicesContext
)

export const ServicesContextProvider: FunctionComponent = ({ children }) => {
  const [areServicesLoaded, setAreServicesLoaded] = useState(false)

  useEffect(() => {
    loadClientServices().then(() => setAreServicesLoaded(true))
  }, [])

  return (
    <ServicesContext.Provider value={{ areServicesLoaded }}>
      {children}
    </ServicesContext.Provider>
  )
}

interface ISercicesContext {
  areServicesLoaded: boolean
}
