import { gql } from '@apollo/client'
import { Permission } from '@mapped/schema-graph-react-apollo'
import { WebAuth } from 'auth0-js'
import axios from 'axios'
import jwt from 'jwt-simple'
import { NextApiRequest } from 'next'
import { EAuthProvider } from '../components/auth/providerButton'
import { CoreApiClient } from '../lib/core-api'
import { Services } from '../services'
import { ECookieName, EStorageKey } from '../types/general'
import { getAllCookies, removeCookie } from '../utils/cookies'
import { getEnumKeys, getEnumValues } from '../utils/enum'
import { logger } from '../utils/logger'

function getWebAuth(): WebAuth {
  const client = require('auth0-js')
  const { auth0 } = Services

  return new client.WebAuth({
    domain: auth0.domain,
    clientID: auth0.client_id,
    redirectUri: auth0.redirect_uri,
    audience: Services.auth0.audience,
    scope: auth0.scope,
    responseType: 'code',
  })
}

export async function renewAuthToken(force?: boolean) {
  const refreshToken = getRefreshToken()

  if (!refreshToken) {
    return false
  }

  if (!force && !isAuthTokenNearToExpire(getAuthToken()!)) {
    return true
  }

  const { data } = await axios.post<IOAuthTokenResponse>(`/api/refreshToken`, {
    refreshToken
  })

  if (data?.error) {
    return false
  }

  setAuthToken(data?.access_token!)
  setRefreshToken(data?.refresh_token!)

  return true
}

export async function renewSessionAuthToken(force?: boolean) {
  const refreshToken = getSessionRefreshToken()

  if (!refreshToken) {
    return false
  }

  if (!force && !isAuthTokenNearToExpire(getSessionAuthToken()!)) {
    return true
  }

  const { data } = await axios.post<IOAuthTokenResponse>(`/api/refreshToken`, {
    refreshToken
  })

  if (data?.error) {
    return false
  }

  setSessionAuthToken(data?.access_token!)
  setSessionRefreshToken(data?.refresh_token!)

  return true
}

export async function tradeCodeForTokens(
  code: string
): Promise<IOAuthTokenResponse> {
  try {
    const { data } = await axios.post<IOAuthTokenResponse>(
      `/api/issueTokens`,
      {
        code,
      }
    )

    if (data?.error) {
      logger.info('[issueTokens fail]', (data as any)?.stack)
    }

    return data
  } catch (e: any) {
    logger.info('[issueTokens fail]', e)
    return {}
  }
}

export async function exchangeToken(subject_token: string, org_id?: string) {
  const res = await axios.post('/api/exchangeToken', {
    subject_token,
    org_id
  })

  if (res?.data?.error) {
    logger.info('[exchangeToken fail]', res?.data?.stack)
  }

  return res.data as {
    error?: string
    access_token?: string
    refresh_token?: string
    expires_in?: number
  }
}

export function isAuthTokenNearToExpire(t: string) {
  const token = decodeToken(t!)

  if (!token || token?.error) {
    return true
  }

  const currentTime = Date.now() / 1000 //
  const margin = 1200 // 20 mins

  if (token.exp <= currentTime + margin) {
    return true
  }

  return false
}

export function authorizeWithCredentials({
  email,
  password,
  state,
}: {
  email: string
  password: string
  signup?: boolean
  state?: string
}): Promise<{ error?: string }> {
  const login = (): Promise<any> =>
    new Promise((resolve) => {
      getWebAuth().login(
        {
          email,
          password,
          realm: 'global',
          state,
          scope: Services.auth0.scope,
        },
        (err) => {
          logger.info(`[auth0 login error]`, err as any)
          resolve({ error: err?.description })
        }
      )
    })

  return login()
}

export function getProviderByConnection(connection: string) {
  switch (connection) {
    case 'google-oauth2':
      return EAuthProvider.GOOGLE
    case 'windowslive':
      return EAuthProvider.MICROSOFT
    case 'github':
      return EAuthProvider.GITHUB
    default:
      return EAuthProvider.EMAIL
  }
}

export function authorizeWithSocial(
  provider: EAuthProvider,
  { state, connection }: { state?: string; connection?: string }
) {
  switch (provider) {
    case EAuthProvider.GOOGLE:
      return getWebAuth().authorize({ connection: 'google-oauth2', state })
    case EAuthProvider.MICROSOFT:
      return getWebAuth().authorize({ connection: 'windowslive', state })
    case EAuthProvider.GITHUB:
      return getWebAuth().authorize({ connection: 'github', state })
    case EAuthProvider.SSO:
      return getWebAuth().authorize({
        connection,
        state,
        redirectUri: Services.auth0.redirect_uri + '?sso=' + connection,
      })
  }
}

export function decodeToken(token?: string) {
  try {
    const t = jwt.decode(token ?? '', '', true) ?? {}

    return {
      authToken: token,
      orgId: t['https://mapped.com/org'],
      email: t['https://mapped.com/email'],
      ...t,
    }
  } catch (error) {
    return { error }
  }
}

export async function fetchLoggedUser(req?: NextApiRequest) {
  try {
    let authToken = req ? getRequestAuthToken(req) : getSessionAuthToken()
    const { permissions } = decodeToken(authToken!)

    const normalizedPermissions = permissions?.map((perm: string) =>
      perm.toUpperCase().replace(/\./g, '_')
    )

    const customerPermissions = getEnumValues(Permission)
    const filteredPermissions = normalizedPermissions?.filter((perm: string) =>
      customerPermissions.includes(perm)
    )

    const query = await CoreApiClient(authToken!).query({
      query: LOGGED_USER_QUERY,
    })

    return { permissions: filteredPermissions, authToken, ...query.data }
  } catch (error) {
    return { error }
  }
}

export function logout() {
  localStorage.removeItem(EStorageKey.AUTH_TOKEN)
  localStorage.removeItem(EStorageKey.REFRESH_TOKEN)
  sessionStorage.removeItem(EStorageKey.AUTH_TOKEN)
  sessionStorage.removeItem(EStorageKey.REFRESH_TOKEN)
  clearNonMappedCookies()
}

export function getRefreshToken() {
  return localStorage.getItem(EStorageKey.REFRESH_TOKEN)
}

export function getSessionRefreshToken() {
  return sessionStorage.getItem(EStorageKey.REFRESH_TOKEN)
}

export function setRefreshToken(refreshToken: string) {
  localStorage.setItem(EStorageKey.REFRESH_TOKEN, refreshToken)
}

export function setSessionRefreshToken(refreshToken: string) {
  sessionStorage.setItem(EStorageKey.REFRESH_TOKEN, refreshToken)
}

export function setAuthToken(authToken: string) {
  localStorage.setItem(EStorageKey.AUTH_TOKEN, authToken)
  window.dispatchEvent(new Event('tokenChange'))
}

export function setSessionAuthToken(token: string) {
  sessionStorage.setItem(EStorageKey.AUTH_TOKEN, token);
  window.dispatchEvent(new Event('tokenChange'))
}

export function getAuthToken() {
  return localStorage.getItem(EStorageKey.AUTH_TOKEN)
}

export function getSessionAuthToken() {
  if (!process.browser) {
    return null
  }

  let token = sessionStorage.getItem(EStorageKey.AUTH_TOKEN);
  return token || getSessionRefreshToken() ? token : getAuthToken();
}

export function getRequestAuthToken(req?: NextApiRequest) {
  return req?.headers?.authorization?.replace('Bearer ', '')
}

export function clearNonMappedCookies() {
  Object.keys(getAllCookies()).forEach((cookie) => {
    if (!getEnumKeys(ECookieName).includes(cookie)) {
      removeCookie(cookie as any)
    }
  })
}

const LOGGED_USER_QUERY = gql`
  query loggedUser {
    user: me {
      id
      name
      email
      nickname
      emailVerified
      roles
    }

    organization {
      id
      name
      stripeCustomerId
      created
    }
  }
`

export interface IOAuthTokenResponse {
  error?: string
  access_token?: string
  refresh_token?: string
  expires_in?: number
  provider?: EAuthProvider
  orgId?: string
}
