import { MappedModalProps } from '@mapped/rivet/dist/mapped/modal'
import { createContext, FunctionComponent, useContext, useState } from 'react'

export const ModalsContext = createContext<IModalsContextValue>(
  {} as IModalsContextValue
)

export const ModalsContextProvider: FunctionComponent = ({ children }) => {
  const [modal, setModal] = useState<IModal>()

  function open(Component: any, props: MappedModalProps) {
    setModal({
      Component,

      props: {
        ...props,

        open: true,

        onClose: () => {
          props?.onClose?.()
          setModal(undefined)
        },
      },
    })
  }

  function close() {
    setModal(undefined)
  }

  const state = {
    modal,
    open: open as TOpenModalMethod,
    close,
  }

  return (
    <ModalsContext.Provider value={state}>{children}</ModalsContext.Provider>
  )
}

export const ModalOnStage: FunctionComponent = () => {
  const { modal } = useContext(ModalsContext)

  if (!modal) {
    return null
  }

  const { Component, props } = modal

  return <Component {...props} />
}

interface IModal {
  Component: FunctionComponent<MappedModalProps & { [key: string]: any }>
  props: MappedModalProps & { [key: string]: any }
}

interface IModalsContextValue {
  modal?: IModal
  open: TOpenModalMethod
  close: () => void
}

// @ts-ignore
type TModalProps<T> = Omit<Parameters<T>[0], 'open' | 'onClose'> & {
  onClose?: () => void
}

type TOpenModalMethod = <C = FunctionComponent<{}>>(
  Component: C,
  props?: TModalProps<C>
) => void
