import { ApolloProvider } from '@apollo/client'
import { FunctionComponent, useContext, useEffect, useState } from 'react'
import { getSessionAuthToken } from '../auth/auth0'
import { CoreApiClient } from '../lib/core-api'
import { ServicesContext } from './services'
import { UserContext } from './user'

export const ApolloContextProvider: FunctionComponent = ({ children }) => {
  const [client, setClient] = useState(CoreApiClient(getSessionAuthToken()!))
  const { areServicesLoaded } = useContext(ServicesContext)
  const { authToken } = useContext(UserContext)

  useEffect(() => {
    if (areServicesLoaded) {
      setClient(CoreApiClient(getSessionAuthToken()!))
    }
  }, [authToken, areServicesLoaded])

  return <ApolloProvider client={client}>{children}</ApolloProvider>
}
