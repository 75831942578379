import { datadogLogs } from '@datadog/browser-logs'
import { Org, Permission, Role, User } from '@mapped/schema-graph-react-apollo'
import {
  FunctionComponent,
  createContext,
  useContext,
  useEffect,
  useState,
} from 'react'
import * as auth0 from '../auth/auth0'
import { Services } from '../services'
import { ServicesContext } from './services'

export const UserContext = createContext<IUserContextValue>(
  {} as IUserContextValue
)

export const UserContextProvider: FunctionComponent = ({ children }) => {
  const { areServicesLoaded } = useContext(ServicesContext)
  const [loggedUser, setLoggedUser] = useState<ILoggedUser | undefined | null>()
  const [time, setTime] = useState(0)
  const { user, organization, permissions, authToken } = loggedUser || {}
  const decodedTokenData = auth0.decodeToken(authToken)

  const isOBO = decodedTokenData['https://mapped.com/obo']

  if (process.browser) {
    ;(window as any).loggedUser = loggedUser
  }

  useEffect(() => {
    if (areServicesLoaded) {
      fetch()
    }

    const tcl = () => setTime(Date.now())
    window.addEventListener('tokenChange', tcl)

    return () => {
      window.removeEventListener('tokenChange', tcl)
    }
  }, [areServicesLoaded])

  useEffect(() => {
    if (user) {
      datadogLogs.setLoggerGlobalContext({
        user: {
          id: user?.id,
          name: user?.name,
          org: {
            id: organization?.id,
            name: organization?.name,
          },
        },
      })
    }
  }, [user])

  async function fetch() {
    if (!auth0.getSessionAuthToken() && !auth0.getSessionRefreshToken()) {
      return setLoggedUser(null)
    }

    await auth0.renewAuthToken()
    await auth0.renewSessionAuthToken()

    const body = await auth0.fetchLoggedUser()

    if (!loggedUser) {
      if (body?.error) {
        auth0.logout()
        return setLoggedUser(null)
      }

      return setLoggedUser(body)
    }

    if (!body.error) {
      setLoggedUser(body)
    }
  }

  const isSandbox = organization?.id === Services.sandbox.org_id && !isOBO
  const isAdmin = !!user?.roles?.includes(Role.Admin)

  const value: IUserContextValue = {
    isOBO,
    isSandbox,
    isFetchingUser: loggedUser === undefined,
    authToken: auth0.getSessionAuthToken()!,
    user,
    organization,
    permissions: permissions || [],
    refetchLoggedUser: fetch,
    isAdmin,
    time,
  }

  return <UserContext.Provider value={value}>{children}</UserContext.Provider>
}

interface IUserContextValue {
  isOBO: boolean
  authToken: string | undefined
  user: User | undefined
  organization: Org | undefined
  permissions: Permission[]
  isAdmin: boolean
  isSandbox: boolean
  isFetchingUser: boolean
  refetchLoggedUser: () => Promise<void>
  time: number
}

export interface ILoggedUser {
  authToken: string
  user: User
  organization: Org
  permissions: Permission[]
}
