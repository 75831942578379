import {
  ApolloClient,
  ApolloLink,
  concat,
  HttpLink,
  InMemoryCache,
} from '@apollo/client'

import { Services } from '../../services'

export function CoreApiClient(
  token?: string,
  tokenType: string = 'Bearer',
  options?: { addTypename: boolean; uri?: string }
) {
  const { client_uri: uri } = Services.graphql_api

  const httpLink = new HttpLink({
    uri: options?.uri || uri,
    credentials: 'omit',
  })

  const authMiddleware = new ApolloLink((operation, forward) => {
    if (!token) {
      // Cancel the request if token is not provided
      return forward(operation)
    }

    operation.setContext({
      headers: {
        Authorization: `${tokenType} ${token}`,
      },
    })

    return forward(operation)
  })

  return new ApolloClient({
    cache: new InMemoryCache(options).restore({}),
    link: concat(authMiddleware, httpLink),
  })
}
