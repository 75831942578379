import { merge } from 'lodash'

export const Services = {
  app: {
    env: process.env.APPLICATION_ENVIRONMENT || 'Local',
    version: '',
    cookie_domain: process.env.COOKIE_DOMAIN || 'localhost',
  },

  auth0: {
    client_id: process.env.AUTH0_CONSOLE_CLIENT_ID,
    client_secret: process.env.AUTH0_CONSOLE_CLIENT_SECRET,
    audience: 'https://api.mapped.com/graphql',
    scope: 'openid profile offline_access',
    domain: process.env.AUTH0_DOMAIN,
    redirect_uri: process.env.AUTH0_REDIRECT_URI,
  },

  graphql_api: {
    client_uri: process.env.GRAPHQL_SERVER_CLIENT_URI,
  },

  contentstack: {
    delivery_token: process.env.CONTENTSTACK_DELIVERY_TOKEN,
    api_key: process.env.CONTENTSTACK_API_KEY,
    env_name: process.env.CONTENTSTACK_ENV_NAME,
  },

  datadog: {
    env: process.env.DATADOG_ENV,
  },

  sandbox: {
    token: process.env.SANDBOX_TOKEN,
    api_url: process.env.SANDBOX_API_URL,
    org_id:
      process.env.APPLICATION_ENVIRONMENT === 'Prod'
        ? 'ORGUmvDdxfFU7UBvJ8Msx2A6J'
        : 'ORGFEdBM93rarcXPD3hjMHxnD',
  },

  console: {
    url: process.env.CONSOLE_URL,
  },

  api_reference: {
    url: process.env.API_REFERENCE_URL,
  },

  grpc_services: {
    mappedgpt: process.env.MAPPEDGPT_GRPC_SERVICE_ENDPOINT,
    orgs: process.env.ORGS_GRPC_SERVICE_ENDPOINT,
  }
}

export async function loadClientServices(): Promise<boolean> {
  if (!process.browser) {
    return true
  }

  const res = await fetch('/api/client-services')
  const clientServices = await res.json()

  merge(Services, clientServices)

  return true
}
