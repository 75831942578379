import { gql } from '@apollo/client'
import { getSessionAuthToken } from '../auth/auth0'
import { CoreApiClient } from '../lib/core-api'

function createMappedUtils() {
  if (!process.browser) {
    return
  }

  ; (window as any)['mapped'] = {
    user: () => (window as any).loggedUser,

    jwt: () => {
      const token = getSessionAuthToken()

      if (!token) {
        return 'No JWT Token to copy'
      }

      copyString(token as string)

      return 'JWT Token copied to clipboard'
    },

    query: (str: string, variables: any) => {
      const token = getSessionAuthToken()

      return CoreApiClient(token!).query({
        query: gql`
          ${str}
        `,
        variables,
      })
    },
  }
}

function copyString(str: string) {
  const input = document.createElement('input')
  input.id = 'strToCopy'
  input.value = str
  input.style.opacity = '0'
  document.body.append(input)
    ; (document.getElementById('strToCopy') as any)?.select()
  document.execCommand('copy')
  document.getElementById('strToCopy')?.remove()
}

createMappedUtils()
