export function getEnumKeys(e: any, includeFallbacks?: boolean) {
  const keys = Object.keys(e).filter((k) => {
    if (includeFallbacks) {
      return true
    }

    return (
      !e[k].toString().includes('UNSPECIFIED') &&
      !e[k].toString().includes('UNRECOGNIZED')
    )
  })

  return keys.map((k) => e[k])
}

export const getEnumValues = getEnumKeys
