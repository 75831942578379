import { getMappedTheme } from '@mapped/rivet/dist/mapped/theme'
import { ThemeProvider } from '@mui/material'
import { FunctionComponent } from 'react'
import { CookiesProvider } from 'react-cookie'
import { QueryClient, QueryClientProvider } from 'react-query'
import { ApolloContextProvider } from './apollo'
import { ModalsContextProvider } from './modals'
import { NotificationsContextProvider } from './notifications'
import { ServicesContextProvider } from './services'
import { UserContextProvider } from './user'

const queryClient = new QueryClient()

export const AppProviders: FunctionComponent = ({ children }) => {
  return (
    <QueryClientProvider client={queryClient}>
      <CookiesProvider>
        <ServicesContextProvider>
          <UserContextProvider>
            <ApolloContextProvider>
              <ThemeProvider theme={getMappedTheme()}>
                <ModalsContextProvider>
                  <NotificationsContextProvider>
                    {children}
                  </NotificationsContextProvider>
                </ModalsContextProvider>
              </ThemeProvider>
            </ApolloContextProvider>
          </UserContextProvider>
        </ServicesContextProvider>
      </CookiesProvider>
    </QueryClientProvider>
  )
}
