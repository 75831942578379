import { datadogLogs } from '@datadog/browser-logs'
import { FunctionComponent, useEffect } from 'react'
import { Services } from '../../services'

export const DatadogInitializer: FunctionComponent = () => {
  useEffect(() => {
    const env = Services.datadog.env || 'miab'
    const version = Services.app.version?.replace(/\+.*/g, '') || '0.0.0'

    datadogLogs.init({
      clientToken: 'pubeb1cfb16d56e23db1f411294f98265f7',
      site: 'datadoghq.com',
      service: 'devportal',
      env,
      version,
      sampleRate: 100,
      forwardErrorsToLogs: false,
      trackSessionAcrossSubdomains: true,
      useCrossSiteSessionCookie: true,
      beforeSend(e) {
        console?.[e?.status]?.(e?.message, e?.metadata)
      },
    })
  }, [])

  return null
}
