import { Github, Microsoft } from '@mapped/rivet/dist/mapped/icons'
import { Google, Key, Mail } from '@mapped/rivet/dist/mui/icons'
import { LoadingButton, LoadingButtonProps } from '@mapped/rivet/dist/mui/lab'
import { styled } from '@mapped/rivet/dist/mui/styles'

const AuthButton = styled(LoadingButton)(() => ({
  width: '100%',
  marginTop: '11px',

  span: {
    flex: 1,
    paddingRight: '24px',
  },
}))

export enum EAuthProvider {
  GOOGLE = 'Google',
  MICROSOFT = 'Microsoft',
  GITHUB = 'Github',
  EMAIL = 'email',
  SSO = 'SSO',
}

interface IButtonProps extends LoadingButtonProps {
  prefix: string
}

export const AuthProviderButtonsMap = {
  [EAuthProvider.GOOGLE]: ({ prefix, ...props }: IButtonProps) => (
    <AuthButton size="medium" {...props}>
      <Google />
      <span>{prefix} Google</span>
    </AuthButton>
  ),

  [EAuthProvider.MICROSOFT]: ({ prefix, ...props }: IButtonProps) => (
    <AuthButton size="medium" variant="outlined" {...props}>
      <Microsoft />
      <span>{prefix} Microsoft</span>
    </AuthButton>
  ),

  [EAuthProvider.GITHUB]: ({ prefix, ...props }: IButtonProps) => (
    <AuthButton
      {...props}
      size="medium"
      sx={(theme) => ({
        background: theme.palette.text.primary,
        ':hover': {
          background: theme.palette.text.a70,
        },
      })}
    >
      <Github />
      <span>{prefix} Github</span>
    </AuthButton>
  ),

  [EAuthProvider.SSO]: ({ prefix, ...props }: IButtonProps) => (
    <AuthButton size="medium" color="secondary" {...props}>
      <Key />
      <span>{prefix} SSO</span>
    </AuthButton>
  ),

  [EAuthProvider.EMAIL]: ({ prefix, ...props }: IButtonProps) => (
    <AuthButton size="medium" {...props}>
      <Mail />
      <span>{prefix} your email</span>
    </AuthButton>
  ),
}
