import '@mapped/rivet/node_modules/graphiql/graphiql.css'
import { CssBaseline } from '@mui/material'
import { AppProps } from 'next/app'
import Head from 'next/head'
import { FunctionComponent, useEffect } from 'react'
import 'react-perfect-scrollbar/dist/css/styles.css'
import smoothscroll from 'smoothscroll-polyfill'
import { renewAuthToken, renewSessionAuthToken } from '../auth/auth0'
import { DatadogInitializer } from '../components/datadog'
import { ThirdyPartyScripts } from '../components/thirdy-party-scripts'
import { ModalOnStage } from '../contexts/modals'
import { AppProviders } from '../contexts/providers'
import '../css/main.css'
import '../utils/mapped'

if (process.browser) {
  smoothscroll.polyfill()
}

const App: FunctionComponent<AppProps> = ({ Component, pageProps }) => {
  return (
    <>
      <Head>
        <title>Mapped Developer Portal</title>
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1, user-scalable=no"
        />
      </Head>

      <AppProviders>
        <CssBaseline />

        <AuthTokenChecker />

        <Component {...pageProps} />

        <DatadogInitializer />

        <ModalOnStage />

        <ThirdyPartyScripts />
      </AppProviders>
    </>
  )
}

const AuthTokenChecker: FunctionComponent = () => {
  useEffect(() => {
    setInterval(
      () => renewAuthToken().then(() => renewSessionAuthToken()),
      2 * 60 * 1000
    )
  }, [])

  return null
}

export default App
